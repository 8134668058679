import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';

const userTestimonials = [
  {
    avatar: <Avatar alt="Tristan Gentile"/>,
    name: 'Tristan Gentile',
    occupation: 'Ambient / Composition',
    testimonial:
      "Hello everyone! My name is Tristan, and I am a twenty-year-old composer from Belgium. I was introduced to music from my early teens, and I strived to make ambient tracks like those found in the S.T.A.L.K.E.R. franchise. This lead me to provide the soundtrack to some fan modifications on the game such as S.T.A.L.K.E.R: Gamma.",
  },
  {
    avatar: <Avatar alt="Todor Bogosavljević"/>,
    name: 'Todor Bogosavljević',
    occupation: 'Mixing / Mastering',
    testimonial:
        "Hey, my name is Todor, and I am a music producer with 7+ years of experience. I started my music-making journey by producing electronic dance music, before moving onto producing songs for other artists. I play electric and acoustic guitar, but I mostly specialize in producing, mixing and mastering tracks.",
},
  {
    avatar: <Avatar alt="Wisław Michalski"/>,
    name: 'Wisław Michalski',
    occupation: 'Orchestral / Composition',
    testimonial:
        "Hello, I am Wisław and I am a clasically trained composer from Poland. I specialize in writing orchestral and grand pieces, which are designed to be played in an auditorium, I also leverage modern technology to create epic-sounding tracks in my bedroom. I have been with Astra since day one.",
},
  {
    avatar: <Avatar alt="Fabijan Despić"/>,
    name: 'Fabijan Despić',
    occupation: 'Pianist / Composition',
    testimonial:
        "Hello everyone, I am Fabijan - a pianist from Slovakia. I never got into recording my music, but I have been taught the piano at an early age, and I loved the softness and emotion of the instrument. I am working for Astra with the rest of the team to write and record piano pieces for emotionally vulnerable moments.",
},
  {
    avatar: <Avatar alt="Matěj Tobiáš Moravec"/>,
    name: 'Matěj Tobiáš Moravec',
    occupation: 'Electronic / Synth',
    testimonial:
        "I am Matěj, an electronic music producer from the Czech Republic. I have many hobbies, such as photography, graphic design, and programming, but music has always had a permanent place in my heart. My main addition to the team are intense synths.",
},
  {
    avatar: <Avatar alt="Alex Whyte" src="" />,
    name: 'Alex Whyte',
    occupation: 'Electronic / Mixing',
    testimonial:
        "Hello, I am Alex - a music producer originally from Belgium, but I have since moved to the UK. I have always liked dance music, and I play live music at many events. I released a lot of tracks over the years, which have amassed over 250,000+ plays on spotify. I went by 'Lil Blyat', but I currently go under the name of 'Arti5'.",
},
];

const whiteLogos = [
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628e8573c43893fe0ace_Sydney-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d520d0517ae8e8ddf13_Bern-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f46794c159024c1af6d44_Montreal-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e891fa22f89efd7477a_TerraLight.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a09d1f6337b1dfed14ab_colorado-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5caa77bf7d69fb78792e_Ankara-white.svg',
];

const darkLogos = [
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628889c3bdf1129952dc_Sydney-black.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d4d8b829a89976a419c_Bern-black.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f467502f091ccb929529d_Montreal-black.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e911fa22f2203d7514c_TerraDark.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a0990f3717787fd49245_colorado-black.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5ca4e548b0deb1041c33_Ankara-black.svg',
];

const logoStyle = {
  width: '64px',
  opacity: 0.3,
};

export default function Team() {
  const theme = useTheme();
  const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

  return (
    <Container
      id="team"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Meet the team
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Behind every track is an artist. Our international team covers any genre - from melanchonic
          ambient tracks, to high-octane heart pumping pieces. We are commited in producing a perfect
          fit for your project.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
                p: 1,
              }}
            >
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  pr: 2,
                }}
              >
                <CardHeader
                  avatar={testimonial.avatar}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}