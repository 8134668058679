import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AccessibilityIcon from '@mui/icons-material/Accessibility';
import HandshakeIcon from '@mui/icons-material/Handshake';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import ReplayIcon from '@mui/icons-material/Replay';
import ScheduleIcon from '@mui/icons-material/Schedule';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';



const items = [
  {
    icon: <WorkHistoryIcon />,
    title: 'Professionalism',
    description:
      'Keep track of your order via a provided link, so that you willl never be left in the dark.',
  },
  {
    icon: <ScheduleIcon />,
    title: 'Dependability',
    description:
      "We are commited to delivering on time. You can be sure that your project won't be waiting on us",
  },
  {
    icon: <ReplayIcon />,
    title: 'Adaptability',
    description:
      'We will provide a perfect fit for your project, no matter your needs.',
  },
  {
    icon: <WhatshotIcon />,
    title: 'Passion',
    description:
      "Enrich your project with music that isn't made to be forgotten, instead it is made to leave an impression.",
  },
  {
    icon: <HandshakeIcon />,
    title: 'Involved',
    description:
      "Get involved with the composers themselves, and be part of the process that brings whats in your mind - to their ears.",
  },
  {
    icon: <AccessibilityIcon />,
    title: 'Human',
    description:
      'None of our music is AI generated, and we believe it should stay that way. You poured your heart out, and we will too.',
  },
  
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: '#06090a',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4">
            Explore
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            Explore why our service stands out: professionalism, dependability and
            adaptability. Converse with our composers and producers to bring your vision to life,
            and be involved in the proccess.
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'grey.800',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}