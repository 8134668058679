import React, { useEffect } from "react";
import { PaletteMode } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import AppAppBar from "./components/landing-page/AppAppBar.tsx";
import Hero from "./components/landing-page/Hero.tsx";
import LogoCollection from "./components/landing-page/LogoCollection.tsx";
import Highlights from "./components/landing-page/Highlights.tsx";
import Pricing from "./components/landing-page/Pricing.tsx";
import Features from "./components/landing-page/Features.tsx";
import Impressions from "./components/landing-page/Impressions.tsx";
import Testimonials from "./components/landing-page/Testimonials.tsx";
import FAQ from "./components/landing-page/FAQ.tsx";
import Footer from "./components/landing-page/Footer.tsx";
import getLPTheme from "./getLPTheme.tsx";
import Team from "./components/landing-page/Testimonials.tsx";

interface ToggleCustomThemeProps {
  showCustomTheme: Boolean;
  toggleCustomTheme: () => void;
}

export default function LandingPage() {
  const [mode, setMode] = React.useState<PaletteMode>("dark");
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  useEffect(() => {
    document.title = "Astra Sounds";
  }, []);

  return (
    <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Hero />
      <Box sx={{ bgcolor: "background.default" }}>
        <LogoCollection />
        <Features />
        <Divider />
        {/* <Impressions />
        <Divider /> */}
        <Team />
        <Divider />
        <Highlights />
        <Divider />
        <Pricing />
        <Divider />
        <FAQ />
        <Divider />
        <Footer mode={mode} toggleColorMode={toggleColorMode}/>
      </Box>
    </ThemeProvider>
  );
}
