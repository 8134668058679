import React, { useEffect } from "react";
import { Card, Link, PaletteMode } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { alpha } from "@mui/material";

import Button from "@mui/material/Button";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import Paper from "@mui/material/Paper";
import getLPTheme from "../getLPTheme.tsx";
import Divider from "@mui/material/Divider";
import AppAppBar from "../components/landing-page/AppAppBar.tsx";
import { ChevronRightRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

interface OrderTrackingPageProps {
  mode: PaletteMode;
  toggleColorMode: () => void;
}

export default function ContactSuccess() {
  const [mode, setMode] = React.useState<PaletteMode>("dark");
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });
  const navigate = useNavigate();

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  useEffect(() => {
    document.title = "Project Tracking";
  }, []);

  const orderTimeline = [
    { status: "Discussion", date: "March 25" },
    { status: "Composition", date: "March 26" },
    { status: "Mixing", date: "March 30" },
    { status: "Mastering", date: "April 4" },
  ];

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          bgcolor: "background.default",
          width: "100%",
          backgroundImage:
            LPtheme.palette.mode === "light"
              ? "linear-gradient(180deg, #CEE5FD, #FFF)"
              : `linear-gradient(#551357, ${alpha("#090E10", 0.0)})`,
          backgroundSize: "100% 20%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
            gap: 2,
            margin: 2,

            maxWidth: 800,
            padding: 5,
            borderRadius: "10px",
            outline: "1px solid",
            outlineColor: alpha(
              LPtheme.palette.mode === "light" ? "#BFCCD9" : "#551357",
              0.5
            ),
            boxShadow: `${
              LPtheme.palette.mode === "light"
                ? "0 0 12px 8px"
                : "0 0 24px 12px"
            } ${
              LPtheme.palette.mode === "light"
                ? alpha("#9CCCFC", 0.2)
                : alpha("#551357", 0.2)
            }`,
          }}
        >
          <Box sx={{ textAlign: "center", margin: 2 }}>
            <Typography sx={{ paddingBottom: 1 }} variant="h2">
              Your message was sent
            </Typography>
            <Typography
              color="text.secondary"
              sx={{ width: { sm: "100%", md: "100%" } }}
            >
              Thank you for showing interest. You will recieve a response to
              your email in the next 24h.
            </Typography>
            <Link
              onClick={() => navigate("/")}
              color="primary"
              variant="body2"
              fontWeight="bold"
              paddingTop={1}
              sx={{
                display: "inline-flex",
                alignItems: "center",
                "& > svg": { transition: "0.2s" },
                "&:hover > svg": { transform: "translateX(2px)" },
                cursor: "pointer",
                ":hover": { cursor: "pointer" },
              }}
            >
              <span>Go back to the home page</span>
              <ChevronRightRounded
                fontSize="small"
                sx={{ mt: "1px", ml: "2px" }}
              />
            </Link>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
