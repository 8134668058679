import * as React from "react";
import { alpha, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

export default function Hero() {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);

  const [isScrolled, setIsScrolled] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleContactUs = () => {
    if (email.trim() === "") {
      navigate('/contact');
    } else if (!isValidEmail(email)) {
      setEmailError(true);
    } else {
      navigate(`/contact?email=${encodeURIComponent(email)}`);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(false);
  };

  const handleButtonClick = () => {
    if (email.trim() === "") {
      setEmailError(true);
    } else if (!isValidEmail(email)) {
      setEmailError(true);
    } else {
      handleContactUs();
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        position: "relative",
        backgroundImage:
          theme.palette.mode === "light"
            ? "linear-gradient(180deg, #D9A8E6, #FFF)"
            : `linear-gradient(#551357, ${alpha("#090E10", 0.0)})`,
        backgroundSize: "100% 20%",
        backgroundRepeat: "no-repeat",
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: "100%", sm: "70%" } }}>
          <Typography
            variant="h1"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignSelf: "center",
              textAlign: "center",
              fontSize: "clamp(3.5rem, 10vw, 4rem)",
            }}
          >
            Create your&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: "clamp(3rem, 10vw, 4rem)",
                color: (theme) =>
                  theme.palette.mode === "light"
                    ? "primary.main"
                    : "primary.light",
              }}
            >
              vision
            </Typography>
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: "center", width: { sm: "100%", md: "80%" } }}
          >
            Professional services to indie creators at a price guaranteed to fit
            the budget. Enhance your work with polished tracks to achieve the
            atmosphere you aim for.
          </Typography>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: "100%", sm: "auto" } }}
          >
            <Tooltip title={emailError ? "Email is required and must be valid" : "Enter a valid email address"} open={emailError} placement="top">
              <TextField
                id="outlined-basic"
                hiddenLabel
                size="small"
                variant="outlined"
                aria-label="Enter your email address"
                placeholder="Your email address"
                value={email}
                onChange={handleEmailChange}
                inputProps={{
                  autoComplete: "off",
                  ariaLabel: "Enter your email address",
                }}
                error={emailError}
              />
            </Tooltip>
            <Button variant="contained" color="primary" onClick={handleButtonClick}>
              Get in contact
            </Button>
          </Stack>
          <Typography
            variant="caption"
            textAlign="center"
            sx={{ opacity: 0.8 }}
          >
            By clicking &quot;Get in contact&quot; you agree to our&nbsp;
            <Link href="/terms" color="primary">
              Terms & Conditions
            </Link>
            .
          </Typography>
        </Stack>
        <Box
          id="image"
          sx={(theme) => ({
            mt: { xs: 8, sm: 10 },
            alignSelf: "center",
            height: { xs: 200, sm: 700 },
            width: "100%",
            backgroundImage:
              theme.palette.mode === "light"
                ? `url("/landing-page/hero-light.jpg")`
                : `url("/landing-page/hero-dark.jpg")`,
            backgroundSize: "cover",
            borderRadius: "10px",
            outline: "1px solid",
            outlineColor:
              theme.palette.mode === "light"
                ? alpha("#BFCCD9", 0.5)
                : alpha("#9CCCFC", 0.1),
            boxShadow:
              theme.palette.mode === "light"
                ? `0 0 12px 8px ${alpha("#551357", 0.2)}`
                : `0 0 24px 12px ${alpha("#551357", 0.2)}`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            filter: isScrolled ? "none" : "blur(8px)",
            transition: "filter 0.5s ease-in-out",
          })}
        >
          <Typography
            variant="h1"
            color="text.primary"
            sx={{
              fontSize: "clamp(3rem, 10vw, 8rem)",
              whiteSpace: "nowrap",
              boxShadow: "5px 4px 6px rgba(28, 28, 28, 0.1)"
            }}
          >
            Astra Sounds
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
