import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PaletteMode, Tooltip } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { alpha, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import getLPTheme from "../getLPTheme.tsx";
import AppAppBar from "../components/landing-page/AppAppBar.tsx";

interface ContactPageProps {
  mode: PaletteMode;
  toggleColorMode: () => void;
}

export default function Contact() {
  const navigate = useNavigate();
  const location = useLocation();
  const [mode, setMode] = useState<PaletteMode>("dark");
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [subject, setSubject] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  const LPtheme = createTheme(getLPTheme(mode));
  const isSmallScreen = useMediaQuery(LPtheme.breakpoints.down("sm"));

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  useEffect(() => {
    document.title = "Contact Us";
    const { email: initialEmail } = parseParams(location.search);
    if (initialEmail) {
      setEmail(initialEmail);
    }
  }, []);

  useEffect(() => {
    setIsButtonDisabled(!isValidEmail(email) || !subject || !message);
  }, [email, subject, message]);

  const parseParams = (search: string) => {
    const params = new URLSearchParams(search);
    return {
      email: params.get("email") || "",
    };
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isValidEmail(email)) {
      setEmailError(true);
      return;
    }

    const formData = new FormData();
    const form = e.currentTarget;

    formData.append("email", email);
    formData.append("subject", subject);
    formData.append("message", message);

    try {
      const response = await fetch("https://astrasounds.com/api/contacts/", {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
        console.log("Message sent successfully!");
        navigate(`/contact/success`);
      } else {
        console.error("Failed to send message");
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailError(false);
  };

  const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(e.target.value);
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          bgcolor: "background.default",
          width: "100%",
          padding: 2,
          backgroundImage:
            LPtheme.palette.mode === "light"
              ? "linear-gradient(180deg, #CEE5FD, #FFF)"
              : `linear-gradient(#551357, ${alpha("#090E10", 0.0)})`,
          backgroundSize: "100% 20%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            justifyContent: "center",
            alignItems: "center",
            p: 2,
            gap: 2,
            maxWidth: 800,
            padding: 5,
            borderRadius: "10px",
            outline: "1px solid",
            outlineColor: alpha(
              LPtheme.palette.mode === "light" ? "#BFCCD9" : "#551357",
              0.5
            ),
            boxShadow: `${
              LPtheme.palette.mode === "light"
                ? "0 0 12px 8px"
                : "0 0 24px 12px"
            } ${
              LPtheme.palette.mode === "light"
                ? alpha("#551357", 0.2)
                : alpha("#551357", 0.2)
            }`,
          }}
        >
          <Box
            sx={{
              flex: "0 0 45%",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              textAlign: isSmallScreen ? "center" : "left",
            }}
          >
            <Typography variant="h1">Get in touch</Typography>
            <Typography
              textAlign={isSmallScreen ? "center" : "left"}
              color="text.secondary"
              sx={{ alignSelf: "left", width: { sm: "100%", md: "80%" } }}
            >
              Once you reach out to us, we will respond within 24h.
            </Typography>
          </Box>

          <Divider orientation={isSmallScreen ? "horizontal" : "vertical"} flexItem />

          <Box sx={{ flex: "0 0 45%", textAlign: "center" }}>
            <form onSubmit={handleSubmit}>
              <TextField
                id="outlined-basic1"
                hiddenLabel
                fullWidth
                size="small"
                variant="outlined"
                aria-label="Enter your email address"
                placeholder="Your email address *"
                value={email}
                onChange={handleEmailChange}
                error={emailError}
                helperText={emailError ? "Please enter a valid email" : ""}
                sx={{ mb: 2 }}
                inputProps={{
                  autoComplete: "off",
                  "aria-label": "Enter your email address",
                }}
              />

              <Tooltip title="Enter your subject" placement="top">
                <TextField
                  id="outlined-basic2"
                  hiddenLabel
                  size="small"
                  variant="outlined"
                  aria-label="Enter your subject"
                  placeholder="Subject *"
                  fullWidth
                  value={subject}
                  onChange={handleSubjectChange}
                  sx={{ mb: 2 }}
                  inputProps={{
                    autoComplete: "off",
                    "aria-label": "Enter your subject",
                  }}
                />
              </Tooltip>

              <Divider orientation="horizontal" flexItem />

              <Tooltip title="Enter your message" placement="top">
                <TextField
                  id="outlined-basic3"
                  hiddenLabel
                  fullWidth
                  size="small"
                  variant="outlined"
                  aria-label="Enter your message"
                  placeholder="Message *"
                  multiline
                  rows={8}
                  value={message}
                  onChange={handleMessageChange}
                  sx={{ mb: 2 }}
                  inputProps={{
                    autoComplete: "off",
                    "aria-label": "Enter your message",
                  }}
                />
              </Tooltip>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isButtonDisabled}
                sx={{
                  bgcolor: "#551357",
                  "&:hover": { bgcolor: alpha("#551357", 0.8) },
                }}
              >
                Send Message
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
