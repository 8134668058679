import * as React from "react";
import Box from "@mui/material/Box";
import { PaletteMode } from "@mui/material";

import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import FacebookIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { ChatBubble, Cloud, PlayArrow, YouTube } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const logoStyle = {
  width: "140px",
  height: "auto",
};

interface AppAppBarProps {
  mode: PaletteMode;
  toggleColorMode: () => void;
}

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {"Copyright © "}
      <Link href="https://astrasounds.com/">Astra Sounds&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}
const scrollToSection = (sectionId: string) => {
  const sectionElement = document.getElementById(sectionId);
  const offset = 128;
  if (sectionElement) {
    const targetScroll = sectionElement.offsetTop - offset;
    sectionElement.scrollIntoView({ behavior: "smooth" });
    window.scrollTo({
      top: targetScroll,
      behavior: "smooth",
    });
  }
};

export default function Footer({ mode, toggleColorMode }: AppAppBarProps) {
  const navigate = useNavigate();

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: "center", md: "left" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            minWidth: { xs: "100%", sm: "60%" },
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "60%" } }}>
            <Box sx={{ ml: "-15px" }}>
              <img
                src={
                  mode === "light"
                    ? "/logo-footer-light.svg"
                    : "/logo-footer-dark.svg"
                }
                style={logoStyle}
                alt="logo of astra sounds"
              />
            </Box>
            <Typography variant="body2" fontWeight={600} gutterBottom>
              Still not sure?
            </Typography>
            <Typography variant="body2" color="text.secondary" mb={2}>
              Email us your worries and we will go over them with you.
            </Typography>
            <Stack direction="row" spacing={1} useFlexGap>
              <Button
                onClick={() => navigate("/contact")}
                variant="contained"
                color="primary"
                sx={{ flexShrink: 0 }}
              >
                Get in contact
              </Button>
            </Stack>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Product
          </Typography>
          <Link
            sx={{ cursor: "pointer", ":hover": { cursor: "pointer" } }}
            color="text.secondary"
            onClick={() => scrollToSection("features")}
          >
            Offer
          </Link>
          <Link
            sx={{ cursor: "pointer", ":hover": { cursor: "pointer" } }}
            color="text.secondary"
            onClick={() => scrollToSection("team")}
          >
            Team
          </Link>
          <Link
            sx={{ cursor: "pointer", ":hover": { cursor: "pointer" } }}
            color="text.secondary"
            onClick={() => scrollToSection("highlights")}
          >
            Explore
          </Link>
          <Link
            sx={{ cursor: "pointer", ":hover": { cursor: "pointer" } }}
            color="text.secondary"
            onClick={() => scrollToSection("pricing")}
          >
            Pricing
          </Link>
          <Link
            sx={{ cursor: "pointer", ":hover": { cursor: "pointer" } }}
            color="text.secondary"
            onClick={() => scrollToSection("faq")}
          >
            FAQs
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Socials
          </Typography>
          <Link
            color="text.secondary"
            href="https://discord.gg/yCcc8cu5c7"
            rel="noreferrer"
          >
            Discord
          </Link>
          <Link
            color="text.secondary"
            href="https://soundcloud.com/astrasounds"
            rel="noreferrer"
          >
            SoundCloud
          </Link>
          <Link
            color="text.secondary"
            href="https://youtube.com/@astrasounds"
            rel="noreferrer"
          >
            YouTube
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Legal
          </Typography>
          <Link color="text.secondary" href="/terms">
            Terms
          </Link>
          <Link
            sx={{ cursor: "pointer", ":hover": { cursor: "pointer" } }}
            color="text.secondary"
            onClick={() => navigate("/contact")}
          >
            Contact
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: { xs: 4, sm: 8 },
          width: "100%",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <div>
          <Link color="text.secondary" href="/terms">
            Terms of Service
          </Link>
          <Copyright />
        </div>
        <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{
            color: "text.secondary",
          }}
        >
          <IconButton
            color="inherit"
            href="https://discord.gg/yCcc8cu5c7"
            aria-label="Discord"
            target="_blank"
            rel="noreferrer"
            sx={{ alignSelf: "center" }}
          >
            <ChatBubble />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://youtube.com/@astrasounds"
            aria-label="X"
            target="_blank"
            rel="noreferrer"
            sx={{ alignSelf: "center" }}
          >
            <PlayArrow />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://soundcloud.com/astrasounds"
            aria-label="Soundcloud"
            target="_blank"
            rel="noreferrer"
            sx={{ alignSelf: "center" }}
          >
            <Cloud />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
