import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./LandingPage.tsx";
import Dashboard from "./Dashboard.tsx";
import OrderTrackingPage from "./OrderTrackingPage.tsx";
import Portfolio from "./Portfolio.tsx";
import Contact from "./components/Contact.tsx";
import ContactSuccess from "./components/ContactSuccess.tsx";
import Terms from "./Terms.tsx";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/contact/success" element={<ContactSuccess />} />
          <Route path="/terms" element={<Terms />} />
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
